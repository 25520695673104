import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoadImage from 'components/LazyLoad/Image';

import Icon from 'components/Icon';
import NextLink from 'components/NextLink';

import styles from './styles.module.scss';

const RubricCard = ({ title, url, description, imageSrc, name, nextLink }) => {
	const cardCx = cx(styles.RubricCard, styles[`RubricCard_type_${name}`]);

	return (
		<NextLink className={cardCx} href={url} next={nextLink}>
			<LazyLoadImage imageSrc={imageSrc}>
				<div
					className={styles.RubricCard__Image}
					style={{ backgroundImage: `url("${imageSrc}")` }}
				/>
			</LazyLoadImage>
			<div className={styles.RubricCard__Body}>
				<div className={styles.RubricCard__Title}>
					{title}
					<Icon
						icon="chevronRightRounded"
						className={styles.RubricCard__Icon}
					/>
				</div>
				<div className={styles.RubricCard__Description}>{description}</div>
			</div>
		</NextLink>
	);
};

RubricCard.propTypes = {
	title: PropTypes.string.isRequired,
	name: PropTypes.string,
	url: PropTypes.string.isRequired,
	description: PropTypes.string,
	imageSrc: PropTypes.string.isRequired,
	nextLink: PropTypes.bool
};

export default RubricCard;
