import _ from 'underscore';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '@culture/common-helpers/cultureUtils';
import EntityPropTypes from 'propTypes/entities';
import {
	NavigationPropType,
	PromoBlockPropType,
	RubricPropType
} from 'propTypes/common';

import SubscribeFormContainer from 'containers/forms/Subscribe';

import Container from 'components/Container';
import GlobalPromoBlock from 'components/GlobalPromoBlock';
import Grid from 'components/Grid';
import LiveCard from 'components/cards/entities/LiveCard';
import MainPromoBlocks from 'components/promoBlocks/MainPromoBlocks';
import BaseSlider from 'components/sliders/BaseSlider';
import PreviewCard from 'components/cards/PreviewCard';
import PromoBlockCard from 'components/cards/entities/PromoBlockCard';
import RubricCard from 'components/cards/RubricCard';
import Section from 'components/Section';
import SocialLinks from 'components/SocialLinks';
import { ImageFormatContext } from 'contexts/ImageFormatContext';
import { Layout, LayoutBlock } from 'components/Layout';
import { PromoGrid, PromoGridCol } from 'components/PromoGrid';
import {
	Subscribe,
	SubscribeAccent,
	SubscribeBlock
} from 'components/Subscribe';

import { rubricsHash } from 'utils/helpers/main';
import { socialsList } from 'utils/helpers/socials';

import { PVIContext } from 'contexts/PVIContext';
import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';
import PushkinDayPromoBlock from 'components/promoBlocks/PushkinDayPromoBlock';
import CatalogCounters from './components/CatalogCounters';
import NewPublications from './components/NewPublications';

const MainTemplate = ({
	menu,
	rubric,
	blocks: {
		promoBlocks,
		publications,
		news,
		broadcast,
		incutPromoBlocks,
		specialProjects
	},
	counters,
	globalPromoBlocks,
	topPromoBlock,
	pushkinDayPromoBlock
}) => {
	const imageFormat = useContext(ImageFormatContext);

	const { panelActive } = useContext(PVIContext);

	const promo = _(globalPromoBlocks).groupBy('placement');
	const promoBlocksExist = !_.isEmpty(promoBlocks) && promoBlocks.length >= 3;
	const preloadImageSrc =
		promoBlocksExist &&
		getImageUrl(promoBlocks[0].thumbnailFile, {
			format: imageFormat,
			params: {
				crop: 'fill',
				gravity: 'center',
				height: 513,
				width: 976
			}
		});
	const prepareSocialList = socialsList.filter(
		(social) => social.icon !== 'podcast'
	);

	return (
		<Layout
			menu={menu}
			rubric={rubric}
			globalPromoBlock={promo.top && promo.top[0]}
			topPromoBlock={topPromoBlock}
			preloadImageSrc={preloadImageSrc}
		>
			{((!_.isEmpty(promoBlocks) && promoBlocks.length >= 3) ||
				!_.isEmpty(publications)) && (
				<LayoutBlock gutter="top">
					<Container>
						<PromoGrid>
							{promoBlocksExist && (
								<>
									<PromoGridCol type="panorama">
										<PromoBlockCard
											entity={promoBlocks[0]}
											params={{ height: 513, width: 976 }}
											keepRatio={false}
											sizeText="large"
										/>
									</PromoGridCol>
									<PromoGridCol type="portrait">
										<PromoBlockCard
											entity={promoBlocks[1]}
											theme="centeredPromo"
											params={{ height: 400, width: 400 }}
											showSubtitle
										/>
									</PromoGridCol>
									<PromoGridCol type="portrait">
										<PromoBlockCard
											entity={promoBlocks[2]}
											params={{ height: 513, width: 308 }}
										/>
									</PromoGridCol>
								</>
							)}
						</PromoGrid>
					</Container>
					<Container gutter="noMobile">
						<PromoGrid gutter="noMobile">
							{!_.isEmpty(publications) && (
								<PromoGridCol type="incut">
									<NewPublications publications={publications} />
								</PromoGridCol>
							)}
						</PromoGrid>
					</Container>
				</LayoutBlock>
			)}

			{!_.isEmpty(pushkinDayPromoBlock) && (
				<LayoutBlock gutter="medium">
					<Container>
						<PushkinDayPromoBlock promoBlock={pushkinDayPromoBlock} />
					</Container>
				</LayoutBlock>
			)}
			{!_(news).isEmpty() && (
				<LayoutBlock theme="light">
					<Section title="Новости" url="/news">
						<Container>
							<Grid colMobileLarge={2} colTabletMedium={3}>
								{_(news).map((newsItem) => (
									<PreviewCard
										entity={newsItem}
										entityType="news"
										key={newsItem._id}
									/>
								))}
							</Grid>
							<Subscribe>
								<SubscribeBlock
									title={
										<span>
											Подпишитесь на рассылку портала КУЛЬТУРА.
											<SubscribeAccent>РФ</SubscribeAccent>
										</span>
									}
									type="primary"
								>
									<SubscribeFormContainer />
								</SubscribeBlock>
								<SubscribeBlock
									title="А также читайте нас в социальных сетях"
									type="secondary"
								>
									<SocialLinks
										data={prepareSocialList}
										theme="bright"
										spacing="middle"
									/>
								</SubscribeBlock>
							</Subscribe>
						</Container>
					</Section>
				</LayoutBlock>
			)}
			<LayoutBlock>
				<Container>
					<Grid colDefault={1} colTabletMedium={3}>
						{_(rubricsHash).map((rubricItem) => (
							<RubricCard
								key={rubricItem.name}
								nextLink={!panelActive}
								{...rubricItem}
							/>
						))}
					</Grid>
				</Container>
			</LayoutBlock>
			{promo.middle && (
				<LayoutBlock>
					<Container>
						<GlobalPromoBlock {...promo.middle[0]} />
					</Container>
				</LayoutBlock>
			)}
			{!_.isEmpty(broadcast) && (
				<LayoutBlock theme="dark">
					<Section title="Live" url="/live" titleTheme="live">
						<Container width="wideSlider">
							<BaseSlider
								theme="full"
								params={{
									slidesPerView: 'auto',
									spaceBetween: 8,
									breakpoints: {
										[SCREEN_BREAKPOINTS.tabletLarge]: {
											slidesPerView: 4,
											spaceBetween: 24
										},
										[SCREEN_BREAKPOINTS.desktopMedium]: {
											slidesPerView: 5,
											spaceBetween: 24
										}
									}
								}}
							>
								{_(broadcast).map((broadcastItem) => (
									<LiveCard
										entity={broadcastItem}
										key={broadcastItem._id}
										typeWidth="fluidLandscapeSlide"
									/>
								))}
							</BaseSlider>
						</Container>
					</Section>
				</LayoutBlock>
			)}
			{!_.isEmpty(counters) && (
				<LayoutBlock>
					<Section title="Каталоги разделов">
						<Container width="wideSlider">
							<CatalogCounters counters={counters} />
						</Container>
					</Section>
				</LayoutBlock>
			)}
			{!_.isEmpty(incutPromoBlocks) && (
				<LayoutBlock>
					<Container>
						<MainPromoBlocks blocks={incutPromoBlocks} />
					</Container>
				</LayoutBlock>
			)}
			{!_.isEmpty(specialProjects) && (
				<LayoutBlock>
					<Section title="Спецпроекты" url="/s">
						<Container>
							<Grid colMobileLarge={2}>
								{_(specialProjects).map((specialProject) => (
									<PromoBlockCard
										key={specialProject._id}
										entity={specialProject}
										params={{ height: 515, width: 824 }}
										sizeText="large"
									/>
								))}
							</Grid>
						</Container>
					</Section>
				</LayoutBlock>
			)}
			{promo.bottom && (
				<LayoutBlock gutter="none">
					<GlobalPromoBlock {...promo.bottom[0]} />
				</LayoutBlock>
			)}
		</Layout>
	);
};

MainTemplate.propTypes = {
	menu: NavigationPropType.isRequired,
	rubric: RubricPropType.isRequired,
	blocks: PropTypes.shape({
		promoBlocks: PropTypes.arrayOf(EntityPropTypes.promoBlock),
		publications: PropTypes.arrayOf(EntityPropTypes.publication),
		news: PropTypes.arrayOf(EntityPropTypes.publication),
		broadcast: PropTypes.arrayOf(EntityPropTypes.movie),
		incutPromoBlocks: PropTypes.arrayOf(EntityPropTypes.incutPromoBlock),
		specialProjects: PropTypes.arrayOf(EntityPropTypes.specialProject)
	}).isRequired,
	counters: PropTypes.object.isRequired,
	globalPromoBlocks: PropTypes.arrayOf(PromoBlockPropType),
	topPromoBlock: PromoBlockPropType,
	pushkinDayPromoBlock: PromoBlockPropType
};

export default MainTemplate;
