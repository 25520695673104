import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Formik } from 'formik';
import { object, string } from 'yup';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const SubscribeForm = ({ onSubmit }) => {
	const schema = useMemo(
		() =>
			object({
				email: string()
					.email('Пожалуйста, введите валидный E-Mail')
					.required('Пожалуйста, введите адрес электронной почты')
			}),
		[]
	);

	return (
		<Formik
			initialValues={{
				email: ''
			}}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				touched,
				errors
			}) => {
				const buttonCx = cx(styles.button, {
					[styles.show]: !!values.email
				});

				return (
					<form className={styles.form} onSubmit={handleSubmit}>
						<div className={styles.inner}>
							<input
								type="text"
								name="email"
								placeholder="Ваш email"
								value={values.email || ''}
								onBlur={handleBlur}
								onChange={handleChange}
								className={styles.input}
							/>
							<button className={buttonCx} type="submit" aria-label="Отправить">
								<Icon icon="send" className={styles.icon} />
							</button>
						</div>
						{touched.email && errors.email && (
							<div className={styles.error}>{errors.email}</div>
						)}
					</form>
				);
			}}
		</Formik>
	);
};

SubscribeForm.propTypes = {
	onSubmit: PropTypes.func.isRequired
};

export default SubscribeForm;
