import _ from 'underscore';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BaseSlider from 'components/sliders/BaseSlider';
import CatalogCard from 'components/cards/CatalogCard';

import { getNumericDeclension } from 'utils/helpers/declensions';
import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

import { catalogCardsData } from 'utils/helpers/main';

const Counters = ({ counters }) => {
	const cardsDataWithCounters = useMemo(
		() =>
			_(catalogCardsData).map(({ subTitles, catalog, ...rest }) => {
				const counter = counters[catalog];
				const subTitle = getNumericDeclension(counter, subTitles);
				return { ...rest, text: String(counter), subTitle };
			}),
		[]
	);

	const sliderParams = useMemo(
		() => ({
			slidesPerView: 'auto',
			spaceBetween: 8,
			breakpoints: {
				[SCREEN_BREAKPOINTS.tabletLarge]: {
					slidesPerView: 4,
					spaceBetween: 24
				}
			}
		}),
		[]
	);

	return (
		<BaseSlider params={sliderParams} theme="full">
			{_(cardsDataWithCounters).map((countersDataItem) => (
				<CatalogCard
					key={countersDataItem.url}
					type="fluid"
					{...countersDataItem}
				/>
			))}
		</BaseSlider>
	);
};

Counters.propTypes = {
	counters: PropTypes.object.isRequired
};

export default Counters;
