import _ from 'underscore';
import { format, parseISO } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import React from 'react';
import PropTypes from 'prop-types';
import { getEntityUrl } from '@culture/utils/helpers/entities';

import Thumbnail from 'components/Thumbnail';

import { ThumbnailFileCropType } from 'propTypes/common';

import styles from './styles.module.scss';

const PreviewCard = ({ entity, entityType }) => {
	const {
		title,
		thumbnailFile,
		thumbnailFileCrop,
		additionalThumbnailFile,
		publishDate
	} = entity;

	const url = getEntityUrl(entityType, entity);
	const date = format(parseISO(publishDate), 'd MMMM', { locale: ruLocale });

	const hasCropParams = !_.isEmpty(thumbnailFileCrop);

	const thumbnailFileParams = hasCropParams
		? { ...thumbnailFileCrop, crop: 'crop', gravity: 'northwest' }
		: {
				width: 165,
				height: 165
		  };

	const fileToShow = hasCropParams
		? thumbnailFile
		: additionalThumbnailFile || thumbnailFile;

	return (
		<a className={styles.card} href={url}>
			<div className={styles.cover}>
				<Thumbnail
					file={fileToShow}
					className={styles.centeredThumbnail}
					params={thumbnailFileParams}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>{title}</div>
				<div className={styles.date}>{date}</div>
			</div>
		</a>
	);
};

PreviewCard.propTypes = {
	entity: PropTypes.shape({
		title: PropTypes.string.isRequired,
		thumbnailFile: PropTypes.object.isRequired,
		thumbnailFileCrop: ThumbnailFileCropType,
		additionalThumbnailFile: PropTypes.object,
		publishDate: PropTypes.string.isRequired
	}).isRequired,
	entityType: PropTypes.oneOf(['news']).isRequired
};

export default PreviewCard;
