import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import cx from 'classnames';
import { getImageUrl } from '@culture/utils/helpers/files';

import { ImageFormatContext } from 'contexts/ImageFormatContext';
import LazyLoadImage from 'components/LazyLoad/Image';

import EntityPropTypes from 'propTypes/entities';

import styles from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

const PromoBlock = memo(({ entity, imageType, inSlider }) => {
	const {
		title,
		text,
		url,
		images: { color, sources }
	} = entity;
	const imageFormat = useContext(ImageFormatContext);

	const imageSrc =
		imageType &&
		getImageUrl(sources[imageType], {
			config: publicRuntimeConfig,
			format: imageFormat,
			params: {
				crop: 'fill',
				gravity: 'center'
			}
		});

	return (
		<a
			href={url}
			className={styles.MainPromoBlock}
			target="_blank"
			style={{ background: `#${color}` }}
			rel="noopener"
		>
			<div className={cx(styles.MainPromoBlock__Content, 'pvi-no-styles')}>
				<div className={styles.MainPromoBlock__Title}>{title}</div>
				{text && (
					<div className={styles.MainPromoBlock__Description}>{text}</div>
				)}
			</div>
			{publicRuntimeConfig.features.lazy && inSlider ? (
				<div
					className={cx(styles.MainPromoBlock__Image, 'swiper-lazy')}
					data-background={imageSrc}
				/>
			) : (
				<LazyLoadImage imageSrc={imageSrc}>
					<div
						className={styles.MainPromoBlock__Image}
						style={{ backgroundImage: `url("${imageSrc}")` }}
					/>
				</LazyLoadImage>
			)}
		</a>
	);
});

PromoBlock.propTypes = {
	entity: EntityPropTypes.incutPromoBlock.isRequired,
	imageType: PropTypes.oneOf(['desktop', 'tablet', 'mobile']),
	inSlider: PropTypes.bool
};

PromoBlock.defaultProps = {
	inSlider: false
};

export default PromoBlock;
