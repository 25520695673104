export const getNumericDeclension = (number, wordVariants) => {
	const lastDigit = number % 10;
	const lastDecade = number % 100;

	if (lastDecade > 10 && lastDecade < 20) {
		return wordVariants[2];
	}
	if (lastDigit === 1) {
		return wordVariants[0];
	}
	if (lastDigit > 1 && lastDigit < 5) {
		return wordVariants[1];
	}
	return wordVariants[2];
};
