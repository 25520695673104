import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SubscribeText = ({ title, children }) => (
	<div className={styles.block}>
		<div className={styles.title}>{title}</div>
		<div>{children}</div>
	</div>
);

SubscribeText.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	children: PropTypes.node.isRequired
};

export default SubscribeText;
