import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const PromoGridCol = ({ type, children }) => {
	const promoGridColCx = cx(styles.col, styles[type]);

	return <div className={promoGridColCx}>{children}</div>;
};

PromoGridCol.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.oneOf(['incut', 'panorama', 'portrait'])
};

export default PromoGridCol;
