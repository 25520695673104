import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SubscribeAccent = ({ children }) => (
	<span className={styles.textAccent}>{children}</span>
);

SubscribeAccent.propTypes = {
	children: PropTypes.node.isRequired
};

export default SubscribeAccent;
