import getConfig from 'next/config';

const {
	publicRuntimeConfig: { assetPrefix }
} = getConfig();

export const rubricsHash = [
	{
		title: 'Посетить',
		name: 'visit',
		description:
			'Культурные события, интересные места и\u00A0достопримечательности России',
		url: '/visit',
		imageSrc: `${assetPrefix}images/main/visit_rubric.jpg`
	},
	{
		title: 'Читать',
		name: 'read',
		description:
			'Статьи об\u00A0искусстве и\u00A0традициях, биографии, книги и\u00A0новости культуры',
		url: '/read',
		imageSrc: `${assetPrefix}images/main/read_rubric.jpg`
	},
	{
		title: 'Смотреть',
		name: 'watch',
		description:
			'Отечественные и\u00A0зарубежные фильмы, лекции, концерты и\u00A0спектакли',
		url: '/watch',
		imageSrc: `${assetPrefix}images/main/watch_rubric.jpg`
	}
];

export const catalogCardsData = [
	{
		catalog: 'movies',
		theme: 'primary',
		title: 'Кино',
		subTitles: ['фильм', 'фильма', 'фильмов'],
		icon: 'cinema',
		url: '/cinema/movies'
	},
	{
		catalog: 'performances',
		theme: 'secondary',
		title: 'Театр',
		subTitles: ['спектакль', 'спектакля', 'спектаклей'],
		icon: 'fan',
		url: '/theaters/performances'
	},
	{
		catalog: 'concerts',
		theme: 'tertiary',
		title: 'Музыка',
		subTitles: ['концерт', 'концерта', 'концертов'],
		icon: 'philharmonic',
		url: '/music/concerts'
	},
	{
		catalog: 'lectures',
		theme: 'quaternary',
		title: 'Образование',
		subTitles: ['лекция', 'лекции', 'лекций'],
		icon: 'lector',
		url: '/lectures/movies'
	},
	{
		catalog: 'russiaImages',
		theme: 'primary',
		title: 'Образы России',
		subTitles: ['место', 'места', 'мест'],
		icon: 'monument',
		url: '/russia'
	},
	{
		catalog: 'museums',
		theme: 'secondary',
		title: 'Музеи',
		subTitles: ['учреждение', 'учреждения', 'учреждений'],
		icon: 'museum',
		url: '/museums/institutes'
	},
	{
		catalog: 'books',
		theme: 'tertiary',
		title: 'литература',
		subTitles: ['книга', 'книги', 'книг'],
		icon: 'book',
		url: '/literature/books'
	},
	{
		catalog: 'architecture',
		theme: 'quaternary',
		title: 'Архитектура',
		subTitles: ['место', 'места', 'мест'],
		icon: 'architecture',
		url: '/architecture/institutes/location-russia'
	}
];
