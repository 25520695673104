import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

import { publicationTypesHash } from '@culture/utils/helpers/publications';
import EntityPropTypes from 'propTypes/entities';

import {
	cardCropTextDefault,
	cardIcons,
	imageCardSizeDefault
} from 'utils/helpers/entities';

import { prepareEntityUrl } from 'utils/helpers/url';
import BaseCard from './BaseCard';

const PublicationCard = ({
	className,
	cropTextParams,
	entity,
	params,
	typeWidth
}) => {
	const {
		title,
		thumbnailFile,
		shortText: description,
		publishDate,
		type,
		showDefaultThumbnail
	} = entity;

	const parsedDate = publishDate ? parseISO(publishDate) : null;
	const date = parsedDate
		? {
				dayMonth: format(parsedDate, 'dd MMM', { locale: ruLocale }).replace(
					'.',
					''
				)
		  }
		: null;

	const url = prepareEntityUrl(entity);

	const badges = [];

	if (entity?.podcasts?.length) {
		badges.push(cardIcons.podcasts);
	}

	const shouldShowDate = !!date && type === publicationTypesHash.news;
	return (
		<BaseCard
			{...{
				badges,
				className,
				cropTextParams,
				description,
				params,
				title,
				typeWidth,
				url
			}}
			thumbnailFile={showDefaultThumbnail ? null : thumbnailFile}
			date={shouldShowDate ? date : null}
			placeholderAlign="center"
			placeholderImage="shortLogo"
		/>
	);
};

PublicationCard.propTypes = {
	badgeIcon: PropTypes.string,
	className: PropTypes.string,
	cropTextParams: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	entity: EntityPropTypes.publication.isRequired,
	params: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),
	typeWidth: PropTypes.oneOf([
		'fixedLandscape',
		'fluidLandscapeSlide',
		'landingLandscape'
	])
};

PublicationCard.defaultProps = {
	cropTextParams: cardCropTextDefault,
	params: imageCardSizeDefault.landscape
};

export default PublicationCard;
