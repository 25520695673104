import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Subscribe = ({ children }) => (
	<div className={styles.subscribe}>{children}</div>
);

Subscribe.propTypes = {
	children: PropTypes.node.isRequired
};

export default Subscribe;
