import _ from 'underscore';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import EntityPropTypes from 'propTypes/entities';

import BaseSlider from 'components/sliders/BaseSlider';

import { useImageType } from '../useImageType';
import PromoBlock from './components/PromoBlock';

const { publicRuntimeConfig } = getConfig();

const MainPromoBlocks = ({ blocks }) => {
	const [imageType] = useImageType(null);

	const sliderParams = useMemo(
		() => ({
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: { delay: 10000 },
			pagination: {
				clickable: true
			}
		}),
		[]
	);

	return blocks.length > 1 ? (
		<BaseSlider
			key={imageType}
			theme="simple"
			params={sliderParams}
			lazy={publicRuntimeConfig.features.lazy}
		>
			{_(blocks).map((block) => (
				<PromoBlock
					key={block._id}
					entity={block}
					imageType={imageType}
					inSlider
				/>
			))}
		</BaseSlider>
	) : (
		<PromoBlock key={imageType} entity={blocks[0]} imageType={imageType} />
	);
};

MainPromoBlocks.propTypes = {
	blocks: PropTypes.arrayOf(EntityPropTypes.incutPromoBlock).isRequired
};

export default MainPromoBlocks;
