import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import icons from './icons';

import styles from './styles.module.scss';

const CatalogCard = ({ title, text, subTitle, theme, icon, url, type }) => {
	const cardCx = cx(
		styles.card,
		styles[theme],
		type && styles[`card_type_${type}`]
	);

	return (
		<a className={cardCx} href={url}>
			<div className={styles.content}>
				<div className={styles.heading}>
					<div className={styles.title}>{title}</div>
					<div className={styles.text}>{text}</div>
					<div className={styles.subtitle}>
						{subTitle}
						<Icon icon="chevronRight" className={styles.subtitleIcon} />
					</div>
				</div>
				{icon && <div className={styles.side}>{icons[icon]}</div>}
			</div>
		</a>
	);
};

CatalogCard.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary'])
		.isRequired,
	icon: PropTypes.oneOf([
		'cinema',
		'fan',
		'philharmonic',
		'lector',
		'monument',
		'book',
		'architecture',
		'museum'
	]),
	url: PropTypes.string,
	type: PropTypes.oneOf(['fluid'])
};

CatalogCard.defaultProps = {
	url: ''
};

export default CatalogCard;
