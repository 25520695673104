import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import SubscribeForm from 'components/forms/SubscribeForm';

import { subscribeActions } from 'ducks/subscribers';

const SubscribeFormContainer = () => {
	const dispatch = useDispatch();

	const onSubmit = useCallback(
		(data) =>
			dispatch(
				subscribeActions.submitSubscribeForm({ termsAccepted: true, ...data })
			),
		[]
	);

	return <SubscribeForm onSubmit={onSubmit} />;
};

export default SubscribeFormContainer;
