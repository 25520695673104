import React from 'react';
import PropTypes from 'prop-types';
import { getEntityUrl } from '@culture/utils/helpers/entities';
import ruLocale from 'date-fns/locale/ru';
import { isPast, parseISO, format } from 'date-fns';
import EntityPropTypes from 'propTypes/entities';

import { cardIcons, imageCardSizeDefault } from 'utils/helpers/entities';

import BaseCard from './BaseCard';

const LiveCard = ({ className, cropTextParams, entity, params, typeWidth }) => {
	const { title, thumbnailFile, publishDate, institute, toDate } = entity;
	const url = getEntityUrl('broadcast', entity);
	const date = parseISO(publishDate);
	const isPassed = toDate && isPast(parseISO(toDate));
	const live = !isPassed && isPast(date);
	const badges = [];

	if (isPassed) {
		badges.push(cardIcons.liveRecord);
	} else if (live) {
		badges.push(cardIcons.liveBroadcast);
	}

	const preparedDate = date
		? {
				dayMonth: format(date, 'dd MMM', { locale: ruLocale }).replace('.', ''),
				dayWeek: format(date, 'eeeeee HH:mm', { locale: ruLocale })
		  }
		: null;

	return (
		<BaseCard
			{...{
				badges,
				className,
				cropTextParams,
				params,
				thumbnailFile,
				title,
				typeWidth,
				url
			}}
			date={preparedDate}
			description={institute?.title}
			keepRatio
		/>
	);
};

LiveCard.propTypes = {
	className: PropTypes.string,
	cropTextParams: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	entity: EntityPropTypes.movie.isRequired,
	params: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),
	typeWidth: PropTypes.oneOf([
		'fixedLandscape',
		'fluidLandscapeSlide',
		'landingLandscape'
	])
};

LiveCard.defaultProps = {
	cropTextParams: {
		title: '2',
		description: '2'
	},
	params: imageCardSizeDefault.landscape
};

export default LiveCard;
