import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const PromoGrid = ({ children, gutter }) => {
	const gridCx = cx(styles.grid, styles[`gutter_${gutter}`]);

	return <div className={gridCx}>{children}</div>;
};

PromoGrid.propTypes = {
	children: PropTypes.node.isRequired,
	gutter: PropTypes.oneOf(['noMobile'])
};

export default PromoGrid;
